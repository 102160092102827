export interface FiltersSectionOrder {
  overviewSection: string[];
  venueType: string[];
  venueStyle: string[];
  venueFeatures: string[];
  specialOffers: string[];
  foodDrinkSection: string[];
  specialistWeddingSection: string[];
  priceSection: string[];
  upcomingEvents: string[];
}

const sectionOrderBlueprint: FiltersSectionOrder = {
  overviewSection: [],
  venueType: [],
  venueStyle: [],
  venueFeatures: [],
  specialOffers: [],
  foodDrinkSection: [],
  specialistWeddingSection: [],
  priceSection: [],
  upcomingEvents: [],
};

const sectionOrder: FiltersSectionOrder = {
  ...sectionOrderBlueprint,
  overviewSection: [
    'bestOfBritain',
    'exclusiveUse',
    'weddingLicence',
    'hasAccommodation',
    'lateNightExtensionAvailable',
    'alcoholLicense',
  ],
  venueType: [
    'countryHouseManorHouse',
    'barn',
    'gardenOutdoor',
    'statelyHome',
    'castle',
    'hotel',
    'cityHotelCityVenue',
    'warehouseFactory',
    'museumAttraction',
    'uniqueVenueType',
    'pubRestaurant',
    'golfCourse',
    'cruiseBoatYacht',
    'sportingVenueStadium',
    'conferenceCentre',
    'placeOfWorship',
    'townHallRegistryOffice',
    'otherVenueType',
  ],
  venueStyle: [
    'classic',
    'intimate',
    'alternative',
    'romantic',
    'lovelyGrounds',
    'unusual',
    'affordable',
    'outdoor',
    'luxury',
    'rustic',
    'beach',
    'modern',
    'unique',
    'urban',
    'blankCanvas',
    'greatViews',
    'historic',
    'asian',
    'casual',
    'formal',
    'stylish',
  ],
  venueFeatures: [
    'churchWithinWalkingDistance',
    'disabledAccess',
    'marqueePermitted',
    'onsiteParking',
    'petFriendly',
  ],
  specialOffers: ['hasDiscounts', 'hasBridebookOffer', 'hasGift', 'hasLateAvailability'],
  foodDrinkSection: [
    'inHouseCatering',
    'externalCateringPermitted',
    'kosherCatering',
    'halalCatering',
    'asianCatering',
  ],
  specialistWeddingSection: [
    'asianWeddingSpecialist',
    'jewishWeddingSpecialist',
    'muslimWeddingSpecialist',
    'otherWeddingSpecialist',
  ],
  priceSection: ['hasQualityPricing'],
  upcomingEvents: ['hasWeddingFair'],
};

const sectionOrderUS: FiltersSectionOrder = {
  ...sectionOrderBlueprint,
  overviewSection: ['hasAccommodation', 'alcoholLicense'],
  venueType: [
    'barn',
    'conferenceCentre',
    'countryHouseManorHouse',
    'cruiseBoatYacht',
    'gardenOutdoor',
    'golfCourse',
    'hotel',
    'museumAttraction',
    'placeOfWorship',
    'pubRestaurant',
    'rooftop',
    'winery',
  ],
  venueFeatures: ['disabledAccess', 'onsiteParking', 'transportation', 'wifi', 'petFriendly'],
  foodDrinkSection: ['inHouseCatering', 'externalCateringPermitted', 'bar'],
  priceSection: ['hasQualityPricing'],
};

const sectionOrderDE: FiltersSectionOrder = {
  ...sectionOrderBlueprint,
  overviewSection: ['exclusiveUse', 'hasAccommodation', 'alcoholLicense'],
  venueType: [
    'countryHouseManorHouse',
    'barn',
    'gardenOutdoor',
    'statelyHome',
    'castle',
    'hotel',
    'cityHotelCityVenue',
    'warehouseFactory',
    'museumAttraction',
    'pubRestaurant',
    'golfCourse',
    'cruiseBoatYacht',
    'sportingVenueStadium',
    'conferenceCentre',
    'placeOfWorship',
    'townHallRegistryOffice',
    'otherVenueType',
  ],
  venueStyle: [
    'classic',
    'intimate',
    'alternative',
    'romantic',
    'lovelyGrounds',
    'unusual',
    'affordable',
    'outdoor',
    'luxury',
    'rustic',
    'beach',
    'modern',
    'unique',
    'urban',
    'blankCanvas',
    'greatViews',
    'historic',
    'asian',
    'casual',
    'formal',
    'stylish',
  ],
  venueFeatures: [
    'churchWithinWalkingDistance',
    'disabledAccess',
    'marqueePermitted',
    'onsiteParking',
    'petFriendly',
  ],
  specialOffers: ['hasLateAvailability'],
  foodDrinkSection: ['inHouseCatering', 'externalCateringPermitted'],
  specialistWeddingSection: [
    'asianWeddingSpecialist',
    'jewishWeddingSpecialist',
    'muslimWeddingSpecialist',
    'otherWeddingSpecialist',
  ],
  priceSection: ['hasQualityPricing'],
};

const sectionOrderFR: FiltersSectionOrder = {
  ...sectionOrderBlueprint,
  overviewSection: ['exclusiveUse', 'hasAccommodation', 'lateNightExtensionAvailable'],
  venueType: sectionOrder.venueType,
  venueStyle: sectionOrder.venueStyle,
  venueFeatures: sectionOrder.venueFeatures,
  specialOffers: ['hasDiscounts', 'hasBridebookOffer', 'hasGift'],
  foodDrinkSection: sectionOrder.foodDrinkSection,
  specialistWeddingSection: sectionOrder.specialistWeddingSection,
  priceSection: sectionOrder.priceSection,
  upcomingEvents: sectionOrder.upcomingEvents,
};

const sectionOrderIE: FiltersSectionOrder = { ...sectionOrder };

const sectionOrderBBGlobal: FiltersSectionOrder = {
  ...sectionOrderBlueprint,
  venueType: [
    'resort',
    'countryHouseManorHouse',
    'castle',
    'barn',
    'conferenceCentre',
    'pubRestaurant',
    'hotel',
    'cruiseBoatYacht',
    'gardenOutdoor',
    'golfCourse',
    'placeOfWorship',
    'uniqueVenueType',
  ],
  venueStyle: sectionOrder.venueStyle,
  venueFeatures: ['disabledAccess', 'onsiteParking', 'petFriendly'],
};

export {
  sectionOrder,
  sectionOrderUS,
  sectionOrderDE,
  sectionOrderFR,
  sectionOrderIE,
  sectionOrderBBGlobal,
};
